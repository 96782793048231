<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.2144 10.6161H7.89019L10.8382 7.40652C10.961 7.2774 11.0589 7.12295 11.1263 6.95218C11.1937 6.78141 11.2291 6.59774 11.2306 6.41188C11.2321 6.22603 11.1996 6.04171 11.1349 5.86969C11.0703 5.69767 10.9748 5.54139 10.8541 5.40997C10.7334 5.27854 10.5898 5.17461 10.4318 5.10423C10.2738 5.03385 10.1046 4.99844 9.93385 5.00005C9.76314 5.00167 9.59444 5.04028 9.43759 5.11364C9.28073 5.187 9.13887 5.29363 9.02027 5.42732L3.87771 11.0262C3.75798 11.1562 3.66299 11.3107 3.59817 11.4807C3.53336 11.6508 3.5 11.8331 3.5 12.0172C3.5 12.2013 3.53336 12.3836 3.59817 12.5537C3.66299 12.7237 3.75798 12.8782 3.87771 13.0082L9.02027 18.6071C9.26275 18.8621 9.5875 19.0031 9.92459 18.9999C10.2617 18.9968 10.5841 18.8496 10.8225 18.59C11.0609 18.3305 11.1961 17.9794 11.199 17.6124C11.2019 17.2454 11.0724 16.8919 10.8382 16.6279L7.89019 13.4155H20.2144C20.5553 13.4155 20.8823 13.2681 21.1234 13.0056C21.3645 12.7431 21.5 12.387 21.5 12.0158C21.5 11.6446 21.3645 11.2885 21.1234 11.026C20.8823 10.7636 20.5553 10.6161 20.2144 10.6161Z"
      fill="#1F2A37"
    />
  </svg>
</template>
