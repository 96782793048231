<script setup lang="ts">
import { watch } from "vue";

const props = defineProps({
  id: {
    type: String,
    default: "",
  },
  isModalShow: {
    type: Boolean,
    default: false,
  },
  withoutCancel: {
    type: Boolean,
    default: false,
  },
  disableButton: {
    type: Boolean,
    default: false,
  },
  borderBlue: {
    type: Boolean,
    default: false,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  withoutClose: {
    type: Boolean,
    default: false,
  },
  withBack: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "",
  },
  saveText: {
    type: String,
    default: "Save",
  },
  cancelText: {
    type: String,
    default: "Cancel",
  },
  size: {
    type: String,
    default: "sm",
  },
  withoutBorder: {
    type: Boolean,
    default: false,
  },
  withoutButton: {
    type: Boolean,
    default: false,
  },
  withoutScroll: {
    type: Boolean,
    default: false,
  },
});
const isModalShow = toRef(props, "isModalShow");
const saveText = toRef(props, "saveText");
const cancelText = toRef(props, "cancelText");
const title = toRef(props, "title");
const size = toRef(props, "size");
const disableButton = toRef(props, "disableButton");
const withBack = toRef(props, "withBack");
const withoutBorder = toRef(props, "withoutBorder");
const withoutScroll = toRef(props, "withoutScroll");

const emit = defineEmits([
  "closeModal",
  "saveAction",
  "handleBack",
  "cancelAction",
  "validate",
]);

const hideModal = () => {
  emit("closeModal");
};
const saveAction = () => {
  emit("saveAction");
};
const cancelAction = () => {
  emit("closeModal");
  emit("cancelAction");
};
const handleBack = () => {
  emit("handleBack");
};
const sizeModal = (size) => {
  if (size === "sm") {
    return "max-w-md";
  } else if (size === "xs") {
    return "max-w-xs";
  } else if (size === "md") {
    return "max-w-lg";
  } else if (size === "lg") {
    return "max-w-xl";
  } else if (size === "xl") {
    return "max-w-3xl";
  }
};
watch(isModalShow, (newValue) => {
  document.body.style.overflow = newValue ? "hidden" : "";
});
</script>
<template>
  <div
    class="fixed inset-0 z-[99999] bg-gray-900 bg-opacity-50 dark:bg-opacity-80"
    :class="isModalShow ? 'flex' : 'hidden'"
  />
  <div
    :id="id"
    tabindex="-1"
    class="fixed left-0 right-0 top-0 z-[99999] h-[calc(100%-1rem)] max-h-full w-full items-center justify-center overflow-x-hidden overflow-y-hidden md:inset-0"
    :class="isModalShow ? 'flex' : 'hidden'"
  >
    <div class="relative max-h-full w-full p-4" :class="sizeModal(size)">
      <!-- Modal content -->
      <div class="relative rounded-lg bg-white shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div
          class="flex items-center rounded-t"
          :class="[
            withBack ? '' : 'justify-between',
            $slots.subheader ? 'p-4 pb-0 md:p-5 md:pb-0' : 'p-4 md:p-5',
            withoutBorder ? '' : 'border-b border-gray-200',
          ]"
        >
          <button v-if="withBack" class="mr-[6px]" @click="handleBack">
            <IconsArrowLeftOutline />
          </button>
          <h3
            v-if="title"
            class="text-lg font-medium text-gray-900 dark:text-white"
          >
            {{ title }}
          </h3>
          <button
            v-if="!withoutClose && !withBack"
            type="button"
            class="ms-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
            @click="hideModal"
          >
            <IconsX class="h-3 w-3" />
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <div :class="$slots.subheader ? 'mt-1 px-4 md:px-5' : ''">
          <slot name="subheader" />
        </div>
        <!-- Modal body -->
        <div
          class="h-auto max-h-[70vh] space-y-4 p-4 md:p-5"
          :class="withoutScroll ? '' : 'overflow-y-auto'"
        >
          <slot name="body" />
        </div>
        <!-- Modal footer -->
        <div
          v-if="!$slots.footer && !withoutButton"
          class="flex items-center justify-between rounded-b p-4 dark:border-gray-600 md:p-5"
          :class="[
            withoutBorder ? '' : 'border-t border-gray-200',
            withoutCancel ? 'grid-cols-1' : 'grid-cols-2',
            fullWidth ? 'gap-2' : '',
          ]"
        >
          <button
            v-if="!withoutCancel"
            type="button"
            class="rounded-lg border bg-white px-5 py-2.5 text-sm font-medium hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-600"
            :class="[
              fullWidth ? 'w-full' : '',
              borderBlue
                ? 'border-[#002D5D] text-[#002D5D]'
                : 'border-gray-200 text-gray-500',
            ]"
            @click="cancelAction()"
          >
            <span v-if="$slots.cancelButton">
              <slot name="cancelButton" />
            </span>
            <span v-else>
              {{ cancelText }}
            </span>
          </button>
          <button
            :id="`submit-${id}`"
            type="button"
            :class="[
              disableButton ? 'flik-button-secondary' : 'flik-button',
              withoutCancel ? 'ml-auto' : '',
              fullWidth ? 'w-full' : '',
            ]"
            :disabled="disableButton"
            @click="disableButton ? {} : saveAction()"
          >
            {{ saveText }}
          </button>
        </div>
        <div
          v-else-if="$slots.footer"
          class="flex items-center justify-between rounded-b p-4 dark:border-gray-600 md:p-5"
          :class="[withoutBorder ? '' : 'border-t border-gray-200']"
        >
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>
